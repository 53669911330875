import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    asideIsCollapse: false,
    drawerIsShow: false,
    routerMessages: [],
    userInfo: {
      userName: '',
      id: undefined
    }
  },
  getters: {
  },
  mutations: {
    asideChange (state, data) {
      if (data !== undefined) {
        state.asideIsCollapse = data
      } else {
        state.asideIsCollapse = !state.asideIsCollapse
      }
    },
    drawerChange (state) {
      state.drawerIsShow = !state.drawerIsShow
    },
    routerMessagesChange (state, data) {
      state.routerMessages = data
    },
    userInfoChange (state, data) {
      state.userInfo.userName = data.realName
      state.userInfo.id = data.id
    }
  },
  actions: {
  },
  modules: {
  }
})

let baseUrl
const href = window.location.href

if (href.indexOf('192.168') > -1) {
  baseUrl = 'http://192.168.1.151:8025'
} else {
  baseUrl = 'https://api2.miszs.zs-tek.com'
}

// 测试 本地
// baseUrl = 'http://192.168.2.21:1001'
// 测试 服务器
// baseUrl = 'http://192.168.1.151:8025'
// 服务器 内网
// baseUrl = 'http://192.168.1.205:1026'
// 服务器 外网
// baseUrl = 'https://api2.miszs.zs-tek.com'

export default baseUrl

import http from '@/utils/network/general/http'

export function getUserInfo (params) {
  return http.get('/UserInfo/GetUserInfo', params)
}

export function getUsers (params) {
  return http.get('/UserInfo/GetUsers', params)
}

export function changePwd (data) {
  return http.post('/UserInfo/ChangePwd', data)
}

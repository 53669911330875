<template>
  <div id="app">
    <router-view />
    <!-- <h1>{{ code }}</h1> -->
    <!-- <h1>{{ infoCode }}</h1> -->
    <!-- <h1>{{ authCode }}</h1> -->
  </div>
</template>

<script>
import { getUserInfo } from '@/api/userInfo'
import { login } from '@/api/dingTalk'
import { getCode } from '@/utils/dd.js'
import * as dd from 'dingtalk-jsapi'

export default {
  name: 'App',
  data () {
    return {
      // code: ''
      infoCode: '',
      authCode: ''
    }
  },
  mounted () {
    // 项目加载前置操作都集中放在路由守卫里
  },
  created () {
    // 钉钉登录操作
    if (dd.env.platform !== 'notInDingTalk') {
      const localZstekMisWebToken = JSON.parse(localStorage.getItem('zsmis')) || null
      const zstekMisDingtalkEntrance = sessionStorage.getItem('zstek-mis-dingtalk-entrance')

      if (localZstekMisWebToken !== null) {
        localStorage.setItem('zstek-mis-web-token',
          JSON.stringify({
            token: localZstekMisWebToken.token,
            uid: localZstekMisWebToken.uid
          })
        )
        getUserInfo({ userId: localZstekMisWebToken.uid })
          .then((res) => {
            this.$store.commit('userInfoChange', res.data.content)
            // entrance条件变多后 后面的判断需要这么写 (window.location.hash==='#/login' && localZstekMisWebToken.entrance === '#/other/other-meeting-check')
            if (zstekMisDingtalkEntrance === '/other/other-meeting-check') {
              this.$router.push({ path: '/other/other-meeting-check' })
            } else {
              this.$message({
                showClose: true,
                message: `欢迎回来! ${res.data.content.realName}`,
                type: 'success'
              })
              // this.$router.push({ path: '/manhour/manhour-record' })
              this.$router.push({ path: '/navigation' })
            }
          })
          .catch((err) => {
            console.log(err)
            this.$message.error('获取用户信息出错! 请重启应用重新登录!')
            localStorage.removeItem('zsmis')
          })
      } else {
        const zstekMisDingtalk = JSON.parse(sessionStorage.getItem('zstek-mis-dingtalk')) || { infoCode: null }

        // 对url做处理得到authCode
        const geturl = window.location.href
        const getcodeinfo = geturl.split('?')[1]
        const getcodes = new URLSearchParams('?' + getcodeinfo)
        const getauthCode = getcodes.get('authCode')
        let authCode = null
        if (getauthCode) {
          authCode = getauthCode.split('#')[0]
        }

        let infoCode = null
        if (zstekMisDingtalk.infoCode) {
          infoCode = zstekMisDingtalk.infoCode
        }

        // alert('节点测试')

        if (!authCode) {
          getCode(code => {
            // 登录处理
            // console.log(code)
            // alert(code)
            // this.code = code
            sessionStorage.setItem('zstek-mis-dingtalk', JSON.stringify({ infoCode: code }))
            window.location.href =
              'https://login.dingtalk.com/oauth2/auth?' +
              'redirect_uri=' + 'https%3A%2F%2Fmiszs.zs-tek.com%2F%23%2Flogin' +
              '&response_type=code' +
              '&client_id=' + 'dingryjfxf5apq4vajjm' +
              '&scope=openid' +
              '&state=' + '' +
              '&prompt=consent'
          })
        }

        if (infoCode && authCode) {
          // alert(infoCode)
          // alert(authCode)
          this.infoCode = infoCode
          this.authCode = authCode
          login({ code: infoCode.toString(), userCode: authCode.toString() })
            .then((res) => {
              // alert(res.data.message)
              if (res.data.message === '没有查询到数据') {
                alert('钉钉免登凭证已过期, 请重新登陆!')
                window.history.go(-2)
              }
              localStorage.setItem('zsmis',
                JSON.stringify({
                  token: res.data.content.token,
                  uid: res.data.content.uid
                })
              )
              localStorage.setItem('zstek-mis-web-token',
                JSON.stringify({
                  token: res.data.content.token,
                  uid: res.data.content.uid
                })
              )
              getUserInfo({ userId: res.data.content.uid })
                .then((res) => {
                  this.$store.commit('userInfoChange', res.data.content)
                  if (zstekMisDingtalkEntrance === '/other/other-meeting-check') {
                    this.$router.push({ path: '/other/other-meeting-check' })
                  } else {
                    this.$message({
                      showClose: true,
                      message: `欢迎回来! ${res.data.content.realName}`,
                      type: 'success'
                    })
                    // this.$router.push({ path: '/manhour/manhour-record' })
                    this.$router.push({ path: '/navigation' })
                  }
                })
                .catch((err) => {
                  console.log(err)
                  this.$message.error('获取用户信息出错!')
                })
            })
            .catch((err) => {
              console.log(err)
            })
        }
      }
    }
  }
}
</script>

<style lang="scss">
html,
body,
#app {
  height: 100%;
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}

.el-message {
  min-width: 320px !important;
}
</style>

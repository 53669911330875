<template>
  <el-dialog
    title="修改密码"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleDialogClose"
  >
    <div class="user-password-form-container">
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        status-icon
      >
        <el-form-item
          label="旧密码"
          :label-width="formLabelWidth"
          prop="oldPass"
        >
          <el-input
            v-model="form.oldPass"
            autocomplete="off"
            show-password
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="新密码"
          :label-width="formLabelWidth"
          prop="pass"
        >
          <el-input
            v-model="form.pass"
            autocomplete="off"
            show-password
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          :label-width="formLabelWidth"
          prop="checkPass"
        >
          <el-input
            v-model="form.checkPass"
            autocomplete="off"
            show-password
            type="password"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button
        type="primary"
        @click="submitForm('form')"
      >确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { changePwd } from '@/api/userInfo'

export default {
  name: 'UserPassword',
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.form.checkPass !== '') {
          this.$refs.form.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      dialogFormVisible: false,
      form: {
        oldPass: '',
        pass: '',
        checkPass: ''
      },
      formLabelWidth: '80px',
      rules: {
        oldPass: [
          { required: true, message: '请输入旧密码' }
        ],
        pass: [
          { required: true, message: '请输入新密码' },
          { validator: validatePass, trigger: 'change' }
        ],
        checkPass: [
          { required: true, message: '请输入确认密码' },
          { validator: validatePass2, trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    handleDialogClose () {
      this.$refs.form.resetFields()
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.requestChangePwd({
            uid: this.$store.state.userInfo.id,
            pwd: this.form.oldPass,
            newPwd: this.form.pass
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    requestChangePwd (data) {
      changePwd(data)
        .then((res) => {
          // console.log(res)
          if (res.data.code === 1) {
            this.$message({
              showClose: true,
              message: res.data.message,
              type: 'success'
            })

            const zstekMisWeb = localStorage.getItem('zstek-mis-web')
            if (zstekMisWeb) {
              const parsedData = JSON.parse(zstekMisWeb)
              if (parsedData.password) {
                parsedData.password = this.form.pass
                // 将修改后的对象序列化为JSON字符串
                const newData = JSON.stringify(parsedData)
                // 将修改后的数据存回本地存储
                localStorage.setItem('zstek-mis-web', newData)
              }
            }

            this.dialogFormVisible = false
          } else {
            this.$message({
              showClose: true,
              message: res.data.message,
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
          this.$message.error('修改密码接口出错!')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  min-width: 600px;
}

::v-deep .el-dialog__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-password-form-container {
  width: 350px;
}

::v-deep .el-icon-circle-check {
  color: #67C23A;
}
</style>

/**
 * 侧边栏和面包屑路由信息匹配
 * @param {*} arr
 * @param {*} str
 */
export function routerMessages(arr, str) {
  const newArray = str.split('/').slice(1)
  const result = []
  let role = arr
  let url = ''
  for (let i = 0; i < newArray.length; i++) {
    const path = '/' + newArray[i]
    for (const ele of role) {
      if (ele.path === path) {
        url += path
        result.push({
          path: url,
          name: ele.name
        })
        role = ele.children
      }
    }
  }
  return result
}

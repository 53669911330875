<template>
  <el-container style="height: 100%;">
    <el-aside
      width="auto"
      v-show="!fold"
    >
      <Aside></Aside>
    </el-aside>
    <el-container>
      <el-header>
        <Header
          :fold="fold"
          @handleClickPendingChangelog=handleClickPendingChangelog
        ></Header>
      </el-header>
      <!-- <Tabs></Tabs> -->
      <el-scrollbar style="height: calc(100% - 60px)">
        <el-main :style="`min-height: ${mainHeight}px`">
          <transition
            name="fade"
            mode="out-in"
          >
            <KeepAlive :include="keepAliveList">
              <router-view></router-view>
            </KeepAlive>
          </transition>
        </el-main>
      </el-scrollbar>
    </el-container>
    <el-drawer
      title=""
      :visible.sync="getShow"
      :direction="'ltr'"
      :before-close="handleClose"
      :with-header="false"
      size="200px"
    >
      <Aside :fold="fold"></Aside>
    </el-drawer>
    <van-popup
      v-model="informationCardShow"
      :close-on-click-overlay="false"
      style="background-color: transparent; width: 100%; height: 100%;"
      duration="0"
    >
      <Card1 @handleClickInformationCardShow="handleClickInformationCardShow"></Card1>
    </van-popup>
    <van-popup
      v-model="pendingChangelogShow"
      position="top"
      :style="{ height: '300px', overflow: 'hidden' }"
      closeable
      close-icon-position="bottom-right"
      :close-on-click-overlay="false"
    >
      <Log></Log>
    </van-popup>
  </el-container>
</template>

<script>
import { Header, Aside, Log, Card1 } from './components'

export default {
  name: 'Layout',
  components: {
    Header,
    Aside,
    Log,
    Card1
  },
  data () {
    return {
      mainHeight: '',
      fold: false,
      drawer: false,
      pendingChangelogShow: false,
      informationCardShow: false,
      keepAliveList: ['ProjectIssuesList231215', 'ManhourList230926']
    }
  },
  computed: {
    getShow () {
      return this.$store.state.drawerIsShow
    }
  },
  created () {
    const lsIntro = JSON.parse(localStorage.getItem('zstek-mis-web-intro')) || {}
    if (!lsIntro.InformationCard) {
      this.informationCardShow = true
    }
    // 关闭通知
    this.informationCardShow = false
  },
  mounted () {
    this.mainHeight = document.body.offsetHeight - 60
    this.fold = !(document.body.clientWidth > 993)
    window.addEventListener('resize', this.eventHandler)
  },
  methods: {
    handleClose () {
      this.$store.commit('drawerChange')
    },
    eventHandler () {
      this.mainHeight = document.body.offsetHeight - 60
      this.fold = !(document.body.clientWidth > 993)
    },
    handleClickPendingChangelog () {
      this.pendingChangelogShow = true
    },
    handleClickInformationCardShow () {
      this.informationCardShow = false
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.eventHandler)
  }
}
</script>

<style lang="scss">
.el-header,
.el-footer {
  background-color: #fff;
  color: #333;
  text-align: center;
}

.el-aside {
  background-color: #000;
  color: #fff;
  line-height: 200px;
}

.el-main {
  background-color: rgb(249, 249, 249);
  color: #333;
  height: 100%;
}

body>.el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.el-drawer {
  background-color: #000 !important;
  color: #fff !important;
  line-height: 200px !important;
}

.fade-enter {
  opacity: 0;
}

.fade-leave {
  opacity: 1;
}

.fade-enter-active {
  transition: opacity .2s;
}

.fade-leave-active {
  opacity: 0;
  transition: opacity .2s;
}
</style>

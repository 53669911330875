<template>
  <div class="layout-log-container">
    <div class="title">待更新日志<span>（{{ time }}）</span></div>
    <ul>
      <li
        v-for="(item, index) in contentInformation"
        :key="index"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'LayoutLog',
  data () {
    return {
      time: '2024-07-17',
      contentInformation: [
        '记录工时模块可补填工时的时间限定为往前15天可补，超出15天不能补'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-log-container {
  padding: 20px;

  .title {
    font-size: 20px;
    margin-bottom: 10px;
    color: red;

    span {
      font-size: 14px;
    }
  }

  ul {
    list-style-type: decimal;
    padding: 5px 5px 0 20px;
    /* Reduse height */
    height: 200px;
    overflow-y: auto;
    background-color: #eeeeee;

    li {
      margin-bottom: 10px;
      font-size: 15px;
    }
  }
}
</style>

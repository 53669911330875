<template>
  <div class="aside-container">
    <div class="logo">
      <div class="logo-container">
        <el-image
          style="width: 32px; height: 32px"
          :src="require('@/assets/images/logo.png')"
          :fit="'cover'"
        ></el-image>
        <div
          v-if="!getCollapse"
          style="margin-left: 15px; width: 0; white-space: nowrap; font-size: 16px;"
        >
          ZS管理系统
        </div>
      </div>
    </div>

    <el-scrollbar style="height: calc(100% - 60px); background-color: #000">
      <el-menu
        :default-active="getRouterPath"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        :collapse="getCollapse"
        background-color="#000"
        text-color="rgb(166, 166, 166)"
        active-text-color="#fff"
      >
        <el-menu-item
          v-if="false"
          index="/navigation"
          @click="handleClick('/navigation')"
        >
          <i class="el-icon-yibiao"></i>
          <span slot="title">导航页 <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></span>
        </el-menu-item>
        <el-menu-item
          index="/home"
          @click="handleClick('/home')"
        >
          <i class="el-icon-yibiao"></i>
          <span slot="title">首页 <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></span>
        </el-menu-item>
        <el-submenu index="/performance">
          <template slot="title">
            <i class="el-icon-wodejixiao"></i>
            <span slot="title">绩效</span>
          </template>
          <el-menu-item
            index="/performance/performance-questionnaire"
            @click="handleClick('/performance/performance-questionnaire')"
          >绩效评价
            <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></el-menu-item>
          <el-menu-item
            v-if="isManagementCenter && $store.state.userInfo.id !== 57 && $store.state.userInfo.id !== 6"
            index="/performance/performance-authority"
            @click="handleClick('/performance/performance-authority')"
          >评价权限 <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></el-menu-item>
          <el-menu-item
            v-if="isManagementCenter"
            index="/performance/performance-analysis"
            @click="handleClick('/performance/performance-analysis')"
          >评价列表 <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></el-menu-item>
        </el-submenu>
        <el-submenu index="/project">
          <template slot="title">
            <i class="el-icon-xiangmu"></i>
            <span slot="title">项目</span>
          </template>
          <el-menu-item-group>
            <span slot="title">项目信息</span>
            <el-menu-item
              index="/project/project-list"
              @click="handleClick('/project/project-list')"
            >项目列表 <el-tag
                type="success"
                size="mini"
              >已上线 </el-tag></el-menu-item>
            <el-menu-item
              index="/project/project-timeline"
              @click="handleClick('/project/project-timeline')"
            >项目时程 <el-tag
                type="success"
                size="mini"
              >已上线 </el-tag></el-menu-item>
            <el-menu-item
              index="/project/project-design"
              @click="handleClick('/project/project-design')"
            >设计变更 <el-tag
                type="success"
                size="mini"
              >已上线 </el-tag></el-menu-item>
          </el-menu-item-group>
          <!-- <el-menu-item-group>
            <span slot="title">节点把控</span>
            <el-menu-item
              index="/project/project-node"
              @click="handleClick('/project/project-node')"
            >节点时间轴 <el-tag
                type="warning"
                size="mini"
              >开发中</el-tag></el-menu-item>
            <el-menu-item
              index="/project/project-node-detail"
              @click="handleClick('/project/project-node-detail')"
            >节点详情 <el-tag
                type="warning"
                size="mini"
              >开发中</el-tag></el-menu-item>
          </el-menu-item-group> -->
          <!-- <el-menu-item-group>
            <span slot="title">项目组</span>
            <el-menu-item
              index="/project/project-team"
              @click="handleClick('/project/project-team')"
            >加入组 <el-tag
                type="warning"
                size="mini"
              >开发中</el-tag></el-menu-item>
            <el-menu-item
              index="/project/project-team-manage"
              @click="handleClick('/project/project-team-manage')"
            >管理组成员 <el-tag
                type="warning"
                size="mini"
              >开发中</el-tag></el-menu-item>
          </el-menu-item-group> -->
          <el-menu-item-group>
            <span slot="title">项目问题</span>
            <el-menu-item
              index="/project/project-issues-fill"
              @click="handleClick('/project/project-issues-fill')"
            >记录问题 <el-tag
                type="success"
                size="mini"
              >已上线</el-tag></el-menu-item>
            <el-menu-item
              index="/project/project-issues-list"
              @click="handleClick('/project/project-issues-list')"
            >问题列表 <el-tag
                type="success"
                size="mini"
              >已上线</el-tag></el-menu-item>
            <!-- <el-menu-item
              index="/project/project-issues"
              @click="handleClick('/project/project-issues')"
            >问题分析 <el-tag
                type="success"
                size="mini"
              >已上线</el-tag></el-menu-item> -->
            <!-- <el-menu-item
              index="/project/project-issues-export"
              @click="handleClick('/project/project-issues-export')"
            >问题图表 <el-tag
                type="success"
                size="mini"
              >已上线</el-tag></el-menu-item> -->
            <el-menu-item
              index="/project/project-issues-analysis"
              @click="handleClick('/project/project-issues-analysis')"
            >问题分析
              <el-tag
                type="warning"
                size="mini"
              >开发中</el-tag></el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <!-- <el-submenu index="/suggestion">
          <template slot="title">
            <i class="el-icon-yijianfankui"></i>
            <span slot="title">建议</span>
          </template>
          <el-menu-item
            index="/suggestion/suggestion-submit"
            @click="handleClick('/suggestion/suggestion-submit')"
          >提交建议 <el-tag
              type="warning"
              size="mini"
            >开发中</el-tag></el-menu-item>
          <el-menu-item
            index="/suggestion/suggestion-list"
            @click="handleClick('/suggestion/suggestion-list')"
          >建议列表 <el-tag
              type="warning"
              size="mini"
            >开发中</el-tag></el-menu-item>
        </el-submenu> -->
        <el-submenu index="/manhour">
          <template slot="title">
            <i class="el-icon-solid-time"></i>
            <span slot="title">工时</span>
          </template>
          <el-menu-item
            index="/manhour/manhour-record"
            @click="handleClick('/manhour/manhour-record')"
          >记录工时 <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></el-menu-item>
          <el-menu-item
            index="/manhour/manhour-condition"
            @click="handleClick('/manhour/manhour-condition')"
          >工作情况 <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></el-menu-item>
          <el-menu-item
            index="/manhour/manhour-list"
            @click="handleClick('/manhour/manhour-list')"
          >工时列表 <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></el-menu-item>
          <!-- <el-menu-item
            index="/manhour/manhour-analysis"
            @click="handleClick('/manhour/manhour-analysis')"
          >工时分析 <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></el-menu-item> -->
          <el-menu-item
            index="/manhour/manhour-fill-situation"
            @click="handleClick('/manhour/manhour-fill-situation')"
          >填写情况
            <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></el-menu-item>
          <!-- <el-menu-item
            index="/manhour/manhour-approve"
            @click="handleClick('/manhour/manhour-approve')"
          >审核工时 <el-tag
              type="warning"
              size="mini"
            >开发中</el-tag></el-menu-item> -->
          <el-menu-item
            index="/manhour/manhour-export"
            @click="handleClick('/manhour/manhour-export')"
          >工时导出 <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></el-menu-item>
        </el-submenu>
        <el-submenu index="/office">
          <template slot="title">
            <i class="el-icon-bangongguanli"></i>
            <span slot="title">办公</span>
          </template>
          <el-menu-item
            index="/office/manhour-business-trips"
            @click="handleClick('/office/manhour-business-trips')"
          >出差情况
            <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></el-menu-item>
          <!-- <el-menu-item
            index="/office/office-absence"
            @click="handleClick('/office/office-absence')"
          >请假情况 <el-tag
              type="warning"
              size="mini"
            >开发中</el-tag></el-menu-item> -->
        </el-submenu>
        <el-submenu index="/customer">
          <template slot="title">
            <i class="el-icon-header-07"></i>
            <span slot="title">客户</span>
          </template>
          <el-menu-item
            index="/customer/customer-management"
            @click="handleClick('/customer/customer-management')"
          >客户管理 <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></el-menu-item>
          <el-menu-item
            index="/customer/customer-feedback"
            @click="handleClick('/customer/customer-feedback')"
          >问题反馈 <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></el-menu-item>
        </el-submenu>
        <el-submenu index="/other">
          <template slot="title">
            <i class="el-icon-ziyuan218"></i>
            <span slot="title">其它</span>
          </template>
          <el-menu-item
            index="/other/other-frequently-web"
            @click="handleClick('/other/other-frequently-web')"
          >工具网站 <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></el-menu-item>
        </el-submenu>
        <el-submenu
          index="/developer"
          v-if="isDeveloper"
        >
          <template slot="title">
            <i class="el-icon-kaifazhe"></i>
            <span slot="title">开发者模式</span>
          </template>
          <el-menu-item
            index="/developer/developer-manhour-batchadd"
            @click="handleClick('/developer/developer-manhour-batchadd')"
          >批量工时添加 <el-tag
              type="success"
              size="mini"
            >已上线</el-tag></el-menu-item>
        </el-submenu>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'LayoutAside',
  props: {
    fold: Boolean
  },
  data () {
    return {
      managementCenterList: [6, 57, 25, 49],
      developerList: [24, 25, 33, 49],
      isCollapse: false,
      isDeveloper: false,
      isManagementCenter: false
    }
  },
  computed: {
    getCollapse () {
      return this.$store.state.asideIsCollapse
    },
    getRouterPath () {
      return this.$route.path
    }
  },
  mounted () {
    if (this.developerList.indexOf(this.$store.state.userInfo.id) > -1 && document.body.clientWidth > 715) {
      this.isDeveloper = true
    }
    if (sessionStorage.getItem('zstek-mis-developer') === 'true') {
      this.isDeveloper = true
    }
    if (this.managementCenterList.indexOf(this.$store.state.userInfo.id) > -1) {
      this.isManagementCenter = true
    }
    if (sessionStorage.getItem('zstek-mis-managementCenter') === 'true') {
      this.isManagementCenter = true
    }
    if (window.location.hostname.indexOf('192.168.') > -1) {
      this.isManagementCenter = true
    }
  },
  methods: {
    handleOpen (key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClick (path) {
      if (this.fold) {
        this.$store.commit('drawerChange')
      }
      this.$router.push({ path })
    }
  }
}
</script>

<style>
.el-scrollbar__bar.is-horizontal>div {
  height: 0 !important;
}
</style>

<style lang="scss" scoped>
::v-deep .el-tag {
  display: none;
}

::v-deep .el-menu-item.is-active {
  background-color: #262525 !important;
}

.el-menu-vertical-demo {
  height: calc(100% - 60px);
  border: 0;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}

::v-deep .el-menu-item-group__title {
  color: #ff8800;
}

.aside-container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .logo {
    width: 100%;
    font-weight: bold;
    height: 60px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo-container {
      width: 100%;
      padding-left: 15px;
      display: flex;
      align-items: center;
    }
  }
}
</style>

<!-- 待更新日志上线 -->
<template>
  <div class="layout-information-card1-container">
    <transition
      name="changeShow"
      appear
      @after-enter="onAfterEnter"
    >
      <el-card class="box-card">
        <p>
          <i class="el-icon-icon-brandnew"></i>
          待更新日志模块上线
        </p>
        <div>实时了解MIS开发的工作进程</div>
      </el-card>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'InformationCard1',
  data () {
    return {
    }
  },
  methods: {
    onAfterEnter () {
      const lsIntro = JSON.parse(localStorage.getItem('zstek-mis-web-intro')) || {}
      lsIntro.InformationCard = true
      localStorage.setItem('zstek-mis-web-intro', JSON.stringify({ ...lsIntro }))
      this.$emit('handleClickInformationCardShow')
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-information-card1-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .box-card {
    min-width: 300px;
    background-image: linear-gradient(200deg, #e0c7e3, #badbe9);
    border-radius: 10px;

    p {
      font-size: 20px;
      font-weight: bold;
      color: rgb(219, 62, 62);
      display: flex;
      align-items: center;
      margin: 0;
      padding-bottom: 15px;

      i {
        font-size: 28px;
      }
    }
  }

  .changeShow-enter-active {
    animation: changeShow 2s;
  }

  @keyframes changeShow {
    0% {
      transform: translateX(0px) translateY(0px);
    }

    30% {
      transform: scale(1.2);
    }

    60% {
      transform: scale(1);
    }

    100% {
      transform: translateX(calc(50vw - 35px)) translateY(calc(-50vh + 20px)) scale(0.1);
    }
  }
}
</style>

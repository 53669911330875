<template>
  <div class="header-container">
    <el-row class="row">
      <el-col
        :span="20"
        style="display: flex; align-items: center;"
      >
        <i
          class="el-icon-liebiaomoshi_kuai"
          style="cursor: pointer;"
          @click="handleCollapse"
        ></i>
        <div
          class="grid-content bg-purple"
          style="margin-left: 15px; font-size: 12px;"
        >
          <el-breadcrumb separator="/">
            <el-breadcrumb-item
              v-for="(item, index) in getRouterMessages"
              :key="index"
              :to="{ path: item.path }"
            >{{ item.name }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple">
          <div class="row-right">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <!-- <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar> -->
                <!-- <el-avatar src="https://api.yimian.xyz/img?type=wallpaper"></el-avatar> -->
                <el-badge is-dot>
                  <el-avatar>{{ getUserInfo.userName[0] }}</el-avatar>
                </el-badge>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>{{ getUserInfo.userName }}</el-dropdown-item>
                <el-dropdown-item @click.native="showChangeLog">
                  <el-badge>待更新日志</el-badge>
                </el-dropdown-item>
                <el-dropdown-item @click.native="showUserPassword">
                  <el-badge value="new">修改密码</el-badge>
                </el-dropdown-item>
                <el-dropdown-item
                  divided
                  @click.native="signout"
                >退出登录</el-dropdown-item>
                <el-dropdown-item
                  style="color: red;"
                  @click.native="handleDDCache"
                  v-if="isDD"
                >清除缓存</el-dropdown-item>
                <el-dropdown-item
                  style="color: red;"
                  @click.native="handleDDClose"
                  v-if="isDD"
                >关闭应用</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="header-container-time">
      <p>{{ time.split('-')[0] }}</p>
      <p>{{ time.split('-')[1] }}</p>
    </div>
    <UserPassword ref="userPassword"></UserPassword>
  </div>
</template>

<script>
import moment from 'moment'
import * as dd from 'dingtalk-jsapi'
import { Dialog } from 'vant'
import UserPassword from '@/views/user/user-password.vue'

export default {
  name: 'LayoutHeader',
  components: {
    UserPassword
  },
  props: {
    fold: Boolean
  },
  data () {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      time: moment().format('YYYY/MM/DD-HH:mm:ss'),
      timer: null,
      isDD: false
    }
  },
  computed: {
    getRouterMessages () {
      return this.$store.state.routerMessages
    },
    getUserInfo () {
      return this.$store.state.userInfo
    }
  },
  created () {
    if (dd.env.platform !== 'notInDingTalk') {
      this.isDD = true
    }
  },
  mounted () {
    this.loginForm = JSON.parse(sessionStorage.getItem('zstek-mis-web'))
    this.timer = setInterval(() => {
      this.time = moment().format('YYYY/MM/DD-HH:mm:ss')
    }, 1000)
  },
  methods: {
    signout () {
      Dialog.confirm({
        message: '是否确定退出？'
      })
        .then(() => {
          // on confirm
          localStorage.removeItem('zstek-mis-web')
          sessionStorage.removeItem('zstek-mis-web')
          localStorage.removeItem('zstek-mis-web-token')
          this.$router.push({ path: '/login' })
        })
        .catch(() => {
          // on cancel
        })
    },
    handleCollapse () {
      if (this.fold) {
        this.$store.commit('asideChange', false)
        this.$store.commit('drawerChange')
      } else {
        this.$store.commit('asideChange')
      }
    },
    handleDDClose () {
      dd.biz.navigation.close({
        onSuccess: function (result) {
          console.log(result, 'result')
        },
        onFail: function (err) {
          console.log(err, 'err')
        }
      })
    },
    handleDDCache () {
      sessionStorage.clear()
      localStorage.clear()
      this.$router.push({ path: '/login' })
    },
    showChangeLog () {
      this.$emit('handleClickPendingChangelog')
    },
    showUserPassword () {
      this.$refs.userPassword.dialogFormVisible = true
    }
  },
  beforeDestroy () {
    // js提供的clearInterval方法用来清除定时器
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  text-align: left;
  height: 100%;
  position: relative;

  .row {
    height: 100%;
    line-height: 0;
    display: flex;
    align-items: center;

    .row-right {
      display: flex;
      justify-content: flex-end;

      .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
      }

      .el-icon-arrow-down {
        font-size: 12px;
      }
    }
  }

  ::v-deep .el-breadcrumb__separator {
    margin: 0 5px;
    color: #606266;
  }

  .header-container-time {
    font-size: 13px;
    position: absolute;
    bottom: 5px;
    right: 50px;

    p {
      line-height: 2px;
    }
  }

  ::v-deep .el-avatar {
    background-color: #000;
  }
}
</style>
